const images = {};

function importAll (requireContext) {
  requireContext.keys().forEach(id => {
    const key = /\.\/(.*).png$/.exec(id)[1];
    images[key] = requireContext(id);
  });
}

importAll(require.context('./', true, /\.png$/));
export default images;