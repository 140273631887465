import utils from '../../../common/utils'
import { useNavigate } from 'react-router-dom';
import { parseURL } from "@common/utils";
import StringUtil from './string-util';
import { traceLog } from '../../../common/api';
import { isWechat } from "@utils"
const rootConf = require('../deploy.json');

let sessionSeq = 1;

export const conf = ({
  // dev
  // "": {
  //   env: utils.getQueryString('env')
  // },
  "2210251838211": {
    env: 'prod'
  }

  // prod

})[rootConf.activity_id];

export const getEnv = () => {
  return conf?.env || "prod";
};

 /**
   * 跳转锚点
  */
export const handleGo2Anchor = () => {
  const anchorPoint = getQueryString('anchorPoint');
  setTimeout(() => {
    if (anchorPoint) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorPoint);
      // 如果对应id的锚点存在，就跳转到锚点
      if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
    }
  }, 200)

};

/**
   * 跳转链接
   * @param link
   */
 export const handleGo2Page = (props, link) => {
  props.history.replace(link);
  // if () {

  //   window.scrollTo(0, 0)

  // }
  const anchorPoint = getQueryString('anchorPoint');
  if (anchorPoint) {
    handleGo2Anchor();
  }
};

//获取url参数
export const getQueryString = (name) => {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  var afterComma = window.location.hash.substr(window.location.hash.indexOf("?") + 1);
  var r = afterComma.match(reg)
  if (r != null) {
    return unescape(r[2])
  }
  var afterComma2 = window.location.search.substr(window.location.search.indexOf("?") + 1);
  var r2 = afterComma2.match(reg)
  if (r2 != null) {
    return unescape(r2[2])
  }
  return null
}

const config = {
  androidDownloadUrl: 'https://i.localhome.cn/v/2303020930819',
  iosAppstoreUrl: 'https://apps.apple.com/cn/app/%E6%B0%91%E5%AE%BF%E5%AE%9D/id1494669783'
}

export const isIOS = () => {
  let u = navigator.userAgent;
  console.log("navigator.userAgent", navigator.userAgent);
  let result = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (result) {
    return true;
  } else {
    return false;
  }
};

export const traceLogFn = async (trace) => {
  try {
    const deviceUuid = window.localStorage.getItem('_deviceUuid') || '';
    const sessionId = StringUtil.randomSessionId();
    const pageContextId = StringUtil.randomSessionId();
    const _trace = {
      deviceUuid,
      sessionId,
      sessionSeq,
      pageName: 'h5-minsubao.net',
      pageContextId,
      actionCode: 'official_minsubao_h5',
      ...trace
    }
    await traceLog({
      traces: [_trace]
    });
    sessionSeq = sessionSeq + 1
  } catch (error) {
    console.log("🚀 ~ file: index.js ~ line 95 ~ App ~ error", error)
  }
}


export const download = (actionCode) => {
  const IS_IOS = isIOS();
  const IS_Wechat = isWechat();
  console.log("IS_IOS", IS_IOS);
  console.log('====', IS_Wechat);
  traceLogFn({ actionCode: actionCode });
  if (IS_Wechat) {
    window.location.href = 'https://i.localhome.cn/v/2303020930819'
  } else {
    window.location.href = 'hudson://100'
    const timeout = setTimeout(() => {
      if (IS_IOS) {
        // 苹果商店链接地址
        // window._hmt.push(['_trackEvent', '下载', '点击', label])
        window.location.href = config.iosAppstoreUrl
      } else {
        // window._hmt.push(['_trackEvent', '下载', '点击', label])
        window.location.href = config.androidDownloadUrl;
      }
    }, 2000)

    // 监听 visibilitychange 事件，判断页面是否被隐藏（例如浏览器跳转到了其他页面）
    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        // 如果页面被隐藏，取消定时器
        clearTimeout(timeout);
      }
    });

    // 监听 pagehide 事件，判断页面是否关闭或跳转
    window.addEventListener('pagehide', function () {
      clearTimeout(timeout);  // 取消定时器
    });
  }
}
// 添加事件监听
export let addEventListenerPolyfill = (event, handler) => {
  if (window.addEventListener) {
    // 使用 addEventListener
    addEventListenerPolyfill = (event, handler) => {
      window.addEventListener(event, handler);
    }
  } else if (window.attachEvent) {
    // 使用 attachEvent
    addEventListenerPolyfill = (event, handler) => {
      window.attachEvent('on' + event, handler);
    }
  }
  addEventListenerPolyfill(event, handler);
}

// 派发事件
export let dispatchCustomEvent = (eventName, detail) => {
  // 兼容性处理
  if (typeof window.CustomEvent !== "function") {
    dispatchCustomEvent = (eventName, detail) => {
      // 如果浏览器不支持 CustomEvent，则定义一个 polyfill
      function CustomEvent (event, params) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
      }
      
      const event = new CustomEvent(eventName, {
        bubbles: true,
        cancelable: true,
        detail: detail
      });
      window.dispatchEvent(event);
    }
  } else {
    dispatchCustomEvent = (eventName, detail) => {
      const event = new CustomEvent(eventName, { detail });
      window.dispatchEvent(event);
    }
  }
  dispatchCustomEvent(eventName, detail);
}
