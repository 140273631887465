import React, { useState, useEffect } from 'react';
import images from '../../assets';
import './index.less';

const BackToTop = () => {
  const [show, setShow] = useState(false);
  const handleScroll = () => {
    setShow(window.scrollY > 600);
  }

  const backTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return show ? (
    <div className="topIcon" onClick={backTop}>
      <img src={images.topIcon} />
    </div>
  ) : null;
}

export default (BackToTop);