import React, { useState, useEffect, useCallback, useContext } from 'react'
import { HashRouter as Router , Route } from 'react-router-dom';
import StringUtil from '@common/string-util';
import BackToTop from './component/BackToTop';
import CustomerService from './component/CustomerService';
import { getQueryString, addEventListenerPolyfill, traceLogFn } from './common/util';
import services from './common/services';
import VConsole from 'vconsole';

const HomePage = React.lazy(() => import('./pages/home/index'));
const ScrollTo = React.lazy(() => import('./component/ScrollTo'));
const Example = React.lazy(() => import('./pages/example'));
const About = React.lazy(() => import('./pages/about'));
const Solution = React.lazy(() => import('./pages/solution'));
const Service = React.lazy(() => import('./pages/service'));
const Price = React.lazy(() => import('./pages/price'));
const SignUp = React.lazy(() => import('./pages/signUp'));



// prod: https://i.localhome.cn/v/
// dev: https://i.localhome.cn/v/
const App = () => {
  const clientWidth = document.documentElement.clientWidth;
  const init = async () => {
    try {
      if (getQueryString('bd_vid')) {
        sessionStorage.setItem('report_type', 'baidu');
        const logidUrl = window.location.href;
        sessionStorage.setItem('baidu_url', logidUrl);
        const handleReport = (newType) => {
          services.postReport({
            channel: 'baidu',
            accessTokenType: '1',
            conversionTypes: [
              {
                logidUrl,
                newType
              }
            ]
          })
        }
        // addEventListenerPolyfill('reportChannel', (e) => {
        //   if (e.detail.newType === 25) {
        //     traceLogFn({
        //       actionCode: "h5_channel_baidu",
        //       extraInfo: e.detail.mobile
        //     });
        //   }
        //   handleReport(e.detail.newType);
        // })
        handleReport(4);
      }
      
      const qhclickid = getQueryString('qhclickid');
      const sourceid = getQueryString('sourceid');
      const impression_id = getQueryString('impression_id');
      if (qhclickid || sourceid || impression_id) {
        sessionStorage.setItem('report_type', '360');
        const params = {
          channel: 'qihoo360'
        };
        if (qhclickid) {
          params.dataIndustry = 'ocpc_ms_convert';
          params.qhclickId = qhclickid;
        } else if (sourceid) {
          params.dataIndustry = 'ocpc_zs_convert';
          params.qhclickId = sourceid;
          params.jzqs = '1293812';
        } else if (impression_id) {
          params.dataIndustry = 'ocpc_web_convert';
          params.impressionId = impression_id;
        }
        sessionStorage.setItem('360_params', JSON.stringify(params))
        // const handleReport = (event, param) => {
        //   const params = {
        //     channel: 'qihoo360',
        //     event,
        //     eventParam: param
        //   };
        //   if (qhclickid) {
        //     params.dataIndustry = 'ocpc_ms_convert';
        //     params.qhclickId = qhclickid;
        //   } else if (sourceid) {
        //     params.dataIndustry = 'ocpc_zs_convert';
        //     params.qhclickId = sourceid;
        //     params.jzqs = '1293812';
        //   } else if (impression_id) {
        //     params.dataIndustry = 'ocpc_web_convert';
        //     params.impressionId = impression_id;
        //   }
        //   services.postReport(params);
        // }
        // addEventListenerPolyfill('reportChannel', (e) => {
        //   if (e.detail.event === 'REGISTERED') {
        //     traceLogFn({
        //       actionCode: "h5_channel_360",
        //       extraInfo: e.detail.mobile
        //     });
        //   }
        //   handleReport(e.detail.event, e.detail.param);
        // })
      }
      
      const deviceUuid = window.localStorage.getItem('_deviceUuid') || StringUtil.randomSessionId();
      window.localStorage.setItem('_deviceUuid', deviceUuid);
      traceLogFn();
    } catch (error) {
      console.log('🚀 ~ file: index.js ~ line 95 ~ App ~ error', error);
    }
  };
  useEffect(() => {
    const viewport = document.querySelector('meta[name="viewport"]');
    const viewportWidth = 375;
    const viewportScale = clientWidth / viewportWidth;
    viewport.setAttribute('content', 'width=' + viewportWidth + ', initial-scale=' + viewportScale + ', minimum-scale=' + viewportScale + ', maximum-scale=' + viewportScale + ', user-scalable=0');
  }, [clientWidth])

  useEffect(() => {
    init();
    return () => {
      sessionStorage.setItem('baidu_url', '');
      sessionStorage.setItem('360_params', '');
      sessionStorage.setItem('report_type', '');
    }
  }, [])

    return (
        <Router>
          <React.Suspense fallback={<div></div>}>
              <ScrollTo>
                <div >
                  <Route exact path="/" component={(props) => <HomePage { ...props } />} />
                  <Route exact path="/price" component={(props) => <Price { ...props } />} />
                  <Route exact path="/example" component={(props) => <Example { ...props } />} />
                  <Route exact path="/about" component={(props) => <About { ...props } />} />
                  <Route exact path="/solution" component={(props) => <Solution { ...props } />} />
                  <Route exact path="/service" component={(props) => <Service { ...props } />} />
                  <Route exact path="/signUp" component={(props) => <SignUp { ...props } />} />
                  <BackToTop />
                  <CustomerService />
                </div>
              </ScrollTo>
          </React.Suspense>
        </Router>
    )
}

// new VConsole();
export default App

