import React, { useEffect, useState } from 'react';
import images from '../../assets';
import './index.less';

function injectContartLibrary () {
  return new Promise((resolve, reject) => {
    const $scriptDOM = document.createElement('script')
    $scriptDOM.type = 'text/javascript'
    $scriptDOM.src = "https://hm.baidu.com/hm.js?7ba723c1a873c9e44f724dfd3366576f"
    $scriptDOM.async = 'async'
    $scriptDOM.onload = resolve
    $scriptDOM.onerror = reject
    document.head.appendChild($scriptDOM)
  })
}
const CustomerService = () => {
  const init = async () => {
    try {
      await injectContartLibrary();
    } catch (error) {
      console.log('====', error);
    }
  }

  useEffect(() => {
    init();
  }, [])


  return null
}

export default (CustomerService);