import util from './fetch-util';
import config from '../config/config';
import moment from 'moment';

const apiMap = {
  // 发送验证码
  postVerifcode: 'POST /user/auth-code/mobile',
  // 注册接口
  postSignup: 'POST /user/sign-up',

  getHomePriceData: `GET https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com//localhomeqy/localsjson/localsPriceData.json?v=${moment().valueOf()}`,

  getWeiRoomCategories: 'POST /weiRoomCategories/page/get',

  getPriceActitvity: `GET https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com//localhomeqy/20241107lukeyunPriceActivity.json?v=${moment().valueOf()}`,

  postReportBaiDu: 'POST /baidu/uploadConvertData',

  postReport: 'POST /uploadConvertData',
  
  postTraceLog: 'POST /traceLog'
};

function bindFetch (input) {
  /**
   * @param {*} data querystring & params & body
   * @param {*} [originRequest={}] originRequest 可以传递特殊的 header
   */
  return (data, originRequest = {}) => {
    // eslint-disable-next-line prefer-const
    let [method, api, useCache] = input.split(' ');
    let cache = api === 'CACHE';

    if (cache || !api) {
      api = method;
      method = 'GET';
    }

    if (!cache) {
      cache = useCache === 'CACHE';
    }

    if (!/^\w+:\/\//.test(api)) {
      api = config.apiPrefix + api;
    }

    originRequest.method = method;
    originRequest.data = data;

    return util.fetch(api, originRequest);
  };
}

function apiFactory (apis) {
  return Object.keys(apis).reduce((interfaces, interfaceMethod) => {
    interfaces[interfaceMethod] = bindFetch(apis[interfaceMethod]);
    return interfaces;
  }, {});
}

export default apiFactory(apiMap);
