import pathToRegexp from 'path-to-regexp';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import config from '../config/config';
import { message } from 'antd';

const TRUE = 1;
const typeToString = Object.prototype.toString;

async function _fetch (input, requestInit = {}) {
  let cloneData;
  let url = input;
  const isFormData = typeToString.call(requestInit.data) === '[object FormData]';
  const token = sessionStorage.getItem('hudson-access-token');

  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    "app_platform": 15
  };

  if (token) {
    defaultHeaders[config.tokenKey] = `Bearer ${token}`;
  }

  requestInit.headers = { ...defaultHeaders, ...requestInit.headers };

  if (isFormData) {
    // fetch处理文件上传类型，不可以设置请求头 https://segmentfault.com/a/1190000010205162
    delete requestInit.headers['Content-Type'];
    cloneData = requestInit.data;
  } else {
    cloneData = cloneDeep(requestInit.data);
  } // handler params
  // 如果url存在占位符，则在data中搜索该替换值，并在cloneData移除该值

  try {
    do {
      let domain = '';
      const urlMatch = input.match(/[a-zA-z]+:\/\/[^/]*/);

      if (urlMatch) {
        [domain] = urlMatch;
        input = input.slice(domain.length);
      }

      const match = pathToRegexp.parse(input);

      if (!match) {
        break;
      }

      url = pathToRegexp.compile(input)(cloneData);
      Array.from(match).forEach(item => {
        const needDelete = item instanceof Object && typeof cloneData[item.name] !== 'undefined';

        if (needDelete) {
          delete cloneData[item.name];
        }
      });
      url = domain + url;
      break;
    } while (TRUE);
  } catch (e) {
    // TODO: 上报异常
    console.error(e);
  } // handler query
  // 如果请求方式为'get'，则将data值拼接到url中

  if (!requestInit.method || requestInit.method.toLowerCase() === 'get') {
    do {
      if (!isFormData && isEmpty(cloneData)) {
        break;
      }

      url += url.indexOf('?') === -1 ? '?' : '&';
      url += qs.stringify(cloneData);
      break;
    } while (TRUE);
  } else {
    // handler body
    if (isFormData) {
      requestInit.body = cloneData;
    } else {
      requestInit.body = JSON.stringify(cloneData);
    }

    delete requestInit.data;
  } // TODO: handler abort
  // 请求结果加工处理
  // requestInit["Access-Control-Allow-Origin"] = "*";
  // requestInit["Access-Control-Allow-Credentials"] = true;
  try {
    let response = await fetch(url, requestInit);

    if (response.headers[config.tokenKey]) {
      sessionStorage.setItem(config.tokenKey, response.headers[config.tokenKey]);
    }

    if (!response) {
      // response为空
      response = '{}';
    }

    const responseData = await response.json();
    const isSuccess = (response.status >= 200 && response.status < 300) || response.status === 304;

    if (responseData.success === false || !isSuccess) {
      responseData.info = {
        url,
        token,
        data: requestInit.data,
        responseHeader: response.headers
      };
      message.error(responseData.errorMsg);
      const status = ["USER_NOT_LOGIN", "USER_TOKEN_INVALID"]
      if (status.includes(responseData.errorCode)) {
        window.sessionStorage.removeItem('hudson-access-token');
        // window.location.reload();
      }
      return Promise.reject(responseData);
    }

    return Promise.resolve(responseData);
  } catch (error) {
    // TODO: 记录异常
    console.error('网络异常', error);
    return Promise.reject({
      success: false,
      errorMsg: '网络异常',
      errorDetail: error,
      info: {
        url,
        requestInit
      }
    });
  }
}

const post = (url, data, opts = {}) => {
  // 处理参数配置的改变以及覆盖
  return _fetch(url, { ...opts, data, method: 'POST' });
};

const get = (url, data, opts = {}) => {
  // 处理参数配置的改变以及覆盖
  return _fetch(url, { ...opts, data, method: 'GET' });
};

const put = (url, data, opts = {}) => {
  // 处理参数配置的改变以及覆盖
  return _fetch(url, { ...opts, data, method: 'PUT' });
};

const deleteApi = (url, data, opts = {}) => {
  // 处理参数配置的改变以及覆盖
  return _fetch(url, { ...opts, data, method: 'DELETE' });
};

export default {
  post,
  get,
  put,
  deleteApi,
  del: deleteApi,
  fetch: _fetch
};
