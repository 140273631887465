import moment from 'moment';
import NP from 'number-precision';
const OSS = require('ali-oss');

export const isIOS = () => {
  let u = navigator.userAgent;
  let result = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (result) {
    return true;
  } else {
    return false;
  }
};

export const isAndroid = () => {
  let u = navigator.userAgent;
  let result = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  if (result) {
    return true;
  } else {
    return false;
  }
};

export const isWechat = () => {
  let ua = navigator.userAgent;
  let result = ua.toLowerCase().indexOf('micromessenger') > -1;
  if (result) {
    return true;
  } else {
    return false;
  }
};

export const supportWebp =
  document
    .createElement('canvas')
    .toDataURL('image/webp')
    .indexOf('data:image/webp') === 0;

export const client = new OSS({
  region: 'oss-cn-shenzhen',
  accessKeyId: 'LTAI4Fp6XQtan9f7P2t4eCVr',
  accessKeySecret: 'V6syMFC4M9nybBJDWGDl4mcPYYSmrm',
  bucket: 'locals-house-prod', // locals-house-prod(生产)
  secure: true // https访问
})

// 生成随机数
export function createUUID (t = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx', ary = 16) {
  var d = new Date().getTime();
  var uuid = t.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * ary) % ary | 0;
      d = Math.floor(d / ary);
      return (c === 'x' ? r : (r && 0x7) || 0x8).toString(ary);
  });
  return uuid;
}

/**
 * @name 文件压缩
 * @description
 *  1、将文件转img对象
 *  2、获取文件宽高比例
 *  3、自定义等比例缩放宽高属性，这里我用的是固定800宽度，高度是等比例缩放
 *  4、canvas重新绘制图片
 *  5、canvas转二进制对象转文件对象，返回
 * @returns { File } 文件
 */
 export async function imgCompress (file) {
  // 将文件转img对象
  const img = await fileToImg(file)
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    // 获取文件宽高比例
    const { width: originWidth, height: originHeight } = img
    // 自定义等比例缩放宽高属性，用的是固定800宽度，高度是等比例缩放
    const scale = +(originWidth / originHeight).toFixed(2) // 比例取小数点后两位)
    const targetWidth = 800 // 固定宽
    const targetHeight = Math.round(800 / scale) // 等比例缩放高
    canvas.width = targetWidth
    canvas.height = targetHeight
    context.clearRect(0, 0, targetWidth, targetHeight)
    // canvas重新绘制图片
    context.drawImage(img, 0, 0, targetWidth, targetHeight)
    // canvas转二进制对象转文件对象，返回
    const type = 'image/png'
    canvas.toBlob(function (blob) {
      const f = new File([blob], file.name, {
        type,
        lastModified: file.lastModified
      })
      resolve(f)
    }, type)
  })
}

export const waterMask = async ({
  url = '',
  textAlign = 'center',
  textBaseline = 'middle',
  font = "20px Microsoft Yahei",
  fillStyle = 'rgba(184, 184, 184, 0.6)',
  content = '请勿外传',
  cb = null,
  file = ''
}) => {
  return new Promise(async (resolve, reject) => {
    content = `仅用于入住登记 ${moment().format('YYYYMMDDHH')}00`;
    // 创建所需要添加水印的img图片
    const img = await fileToImg(file);
    img.crossOrigin = "anonymous";

    img.onload = function () {
      let max = img.width > img.height ? img.width : img.height;
      let height = 80 / 600 * max; //两个水印之间的垂直高度
      let width = 30 / 600 * max; //两个水印之间的水平高度
      let fontSize = 20 / 600 * max; //水印字体大小
      let canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      let ctx = canvas.getContext("2d");

      ctx.drawImage(img, 0, 0, img.width, img.height);

      ctx.textAlign = 'left';
      ctx.textBaseline = 'middle';
      ctx.font = `${fontSize}px Microsoft Yahei`;
      ctx.fillStyle = fillStyle;

      ctx.rotate(17 * Math.PI / 180)
      let i = 0, j = 0, waterMarkerWidth = content.split("").length * fontSize;
      for (i = 0; i < img.width / (waterMarkerWidth); i++) {
        for (j = 0; j < img.height / (height - 20); j++) {
          if (j === 0) {
            ctx.fillText(content, i * (waterMarkerWidth + 0), -height, img.width)
          }
          ctx.fillText(content, i * (waterMarkerWidth + 0), j * height, img.width)
        }
      }
      const base64Url = canvas.toDataURL();
      const file = base64ConvertFile(base64Url)
      resolve(file)
    }

  })
}

// file转换成img对象
function fileToImg (file) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const reader = new FileReader()
    reader.onload = function (e) {
      img.src = e.target.result
    }
    reader.onerror = function (e) {
      reject(e)
    }
    reader.readAsDataURL(file)
    img.onload = function () {
      resolve(img)
    }
    img.onerror = function (e) {
      reject(e)
    }
  })
}

const base64ConvertFile = (urlData) => {
    var arr = urlData.split(',')
    var type = arr[0].match(/:(.*?);/)[1]
    var fileExt = type.split('/')[1]
    var bstr = atob(arr[1])
    var n = bstr.length
    var u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], 'filename.' + fileExt, {
      type: type
    })
  }

  /**
 * 解析后端返回的金额
 * @param {*} money 分
 */

export const parseMoney = money => {
  return NP.divide(money || 0, 100);
};
