const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
const chars2 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
// 获取指定长度随机字符串
const randomStr = (length = 1, charsType = 'BASE') => {
  let str = '';
  const charsBaseData = charsType === 'BASE' ? chars : chars2;
  for (let index = 0; index < length; index++) {
    const randomInex = Math.round(Math.random() * (charsBaseData.length - 1));
    str += charsBaseData[randomInex];
  }
  return str;
};
// 实现iOS沙盒的文件随机命名规则
const randomFileName = () => `${randomStr(8)}-${randomStr(4)}-${randomStr(4)}-${randomStr(4)}-${randomStr(12)}`;

// 实现iOS沙盒的文件随机命名规则
const randomSessionId = () => `${randomStr(8, 'ALL')}`;

const StringUtil = {
  isJsonString (str) {
    if (typeof str === 'string') {
      try {
        const obj = JSON.parse(str);
        if (typeof obj === 'object' && obj) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    }
  },
  // 去空格
  trim (str) {
    str += '';
    str = str.replace(/\s+/g, '');
    if (str.charCodeAt(0) === 8237) str = str.substring(1); // 解决粘贴带入不明字符的问题
    if (str.charCodeAt(str.length - 1) === 8236) str = str.substring(0, str.length - 1); // 解决粘贴带入不明字符的问题
    return str;
  },

  // 去除字符串中的非数字
  removeBesidesNum (str) {
    str += '';
    return str.replace(/[^0-9]/ig, '');
  },

  isNumStr (str) {
    const n = Number(str);
    // eslint-disable-next-line no-restricted-globals
    return (!isNaN(n));
  },

  changeFormatToTime (str) {
    const date = new Date(str);
    return date.getTime();
  },

  // all space or \\n or empty return false, else return false 防止输入纯空格字符串，或空字符串
  isValidInput: str => (typeof str === 'string' && str.match(/^\s*$/) == null),

  randomStr,
  randomFileName,
  randomSessionId

};

export default StringUtil;
